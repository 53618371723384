.Dropdown {
    min-width: 180px;
    z-index: 2;
    position: absolute;
    flex-direction: column;

    .container {
        display: flex;
        width: 100%;
        background: rgb(var(--lightSectionBack));
        border-radius: 0.5rem;
        overflow: hidden;
        padding-top: var(--tinyPadding);
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        z-index: 2;
    }

    .arrow {
        z-index: 3;
        position: absolute;
        top: calc(var(--standardPadding) * -1);
        left: var(--largePadding);
    }
}

.backdrop {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
