.UndoRedo {
    display: flex;
    justify-content: center;

    :global(.Icon) {
        width: 16px;
        height: 16px;
    }

    > button:disabled {
        background: rgb(var(--sectionBack)) !important;
        border-color: rgb(var(--sectionBack)) !important;
    }
}
