@use 'mixins';

.RichImageBlock {
    width: 100%;
    max-width: 100%;
    height: auto;
    position: relative;
    margin-bottom: var(--standardPadding);

    @include mixins.responsive(m, below) {
        width: 100vw;
        max-width: 100vw;
        margin-left: 50%;
        transform: translateX(-50%);
    }

    :global(.rich-content-image) {
        width: 100%;
        height: auto;
        max-width: 100%;
        display: block;
        border-radius: var(--standardRadius);
    }
}

.image-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    margin-bottom: var(--standardPadding);
}

.credit {
    position: absolute;
    bottom: 0.75em;
    right: 0.75em;
    color: rgb(var(--background));
    font-size: var(--heading-x-small-font-size);
    background-color: rgba(var(--textColor), 0.5);
    padding: var(--smallPadding);
    border-radius: var(--standardRadius);

    @include mixins.responsive(m, below) {
        font-size: var(--caption-small-font-size);
        padding: var(--tinyPadding);
    }
}

.caption {
    font-size: var(--caption-font-size);
    margin-top: var(--smallPadding);
    text-align: center;
    margin-bottom: var(--standardPadding);
    color: rgb(var(--lighterTextColor));

    @include mixins.responsive(m, below) {
        font-size: var(--caption-small-font-size);
    }
}
