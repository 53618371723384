.InlineToolbarPlugin {
    width: var(--hugePadding);
    height: var(--hugePadding);
    position: absolute;
    bottom: var(--hugePadding);
    right: var(--hugePadding);
    z-index: 1;

    .opened {
        color: rgb(var(--invertedTextColor));
        background: rgb(var(--mainColor));
        transform: rotate(45deg);
    }

    .closed {
        color: rgb(var(--mainColor));
        background: rgb(255, 255, 255);
        transform: rotate(0deg);
    }

    // TODO: We should improve the positioning of the dropdown to be dynamic based on the items in it.
    // Also according to design it should not be one long row, but after 6 items it should be two rows.
    // Now hardcoding it to make it align properly for tomorrows's demo.
    .position {
        position: absolute;
        margin: auto;
        right: 0px;
        margin-left: calc(-131.5px * var(--dropdownItems));
        left: var(--tinyPadding);
    }

    .container {
        padding: var(--standardPadding);
        background: rgb(var(--lightSectionBack));
        gap: var(--standardPadding);
        display: flex;
    }

    .item {
        height: 80px;
        width: 121px;
        border: solid 1px rgb(var(--lightSectionBorder));
        border-radius: var(--standardRadius);
        display: flex;

        &:hover {
            background: rgb(var(--mainColor));
            color: rgb(var(--invertedTextColor));
            border-radius: var(--standardRadius);
        }
    }

    .dropDown {
        min-width: 121px;
        top: var(--standardPadding);
        display: flex;

        > div {
            left: auto;
            right: var(--standardPadding);
            padding-top: 0;
        }
    }
}
