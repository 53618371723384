@use 'mixins';

.instagramEmbed {
    background: rgb(var(--background));
    border: 0;
    border-radius: 3px;
    box-shadow:
        0 0 1px 0 rgba(0, 0, 0, 0.5),
        0 1px 10px 0 rgba(0, 0, 0, 0.15);
    margin: 1px;
    max-width: 540px;
    min-width: 326px;
    padding: 0;
    width: 99.375%;
}

.embedContent {
    padding: 16px;
}

.embedLink {
    background: rgb(var(--background));
    line-height: 0;
    padding: 0;
    text-align: center;
    text-decoration: none;
    width: 100%;
}

.embedHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.avatar {
    background-color: #f4f4f4;
    border-radius: 50%;
    flex-grow: 0;
    height: 40px;
    margin-right: 14px;
    width: 40px;
}

.profileInfo {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
}

.profileName {
    background-color: #f4f4f4;
    border-radius: 4px;
    flex-grow: 0;
    height: 14px;
    margin-bottom: 6px;
    width: 100px;
}

.profileUsername {
    background-color: #f4f4f4;
    border-radius: 4px;
    flex-grow: 0;
    height: 14px;
    width: 60px;
}

.embedPadding {
    padding: 19% 0;
}

.embedSvgContainer {
    display: block;
    height: 50px;
    margin: 0 auto 12px;
    width: 50px;
}

.embedTextWrapper {
    padding-top: 8px;
}

.embedText {
    color: #3897f0;
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 550;
    line-height: 18px;
}

.embedRow {
    display: flex;
    flex-direction: row;
    margin-bottom: 14px;
    align-items: center;
}

.dotOne {
    background-color: #f4f4f4;
    border-radius: 50%;
    height: 12.5px;
    width: 12.5px;
    transform: translateX(0) translateY(7px);
}

.dotTwo {
    background-color: #f4f4f4;
    height: 12.5px;
    transform: rotate(-45deg) translateX(3px) translateY(1px);
    width: 12.5px;
    flex-grow: 0;
    margin-right: 14px;
    margin-left: 2px;
}

.dotThree {
    background-color: #f4f4f4;
    border-radius: 50%;
    height: 12.5px;
    width: 12.5px;
    transform: translateX(9px) translateY(-18px);
}

.sideGroup {
    margin-left: 8px;
}

.smallCircle {
    background-color: #f4f4f4;
    border-radius: 50%;
    flex-grow: 0;
    height: 20px;
    width: 20px;
}

.triangle {
    width: 0;
    height: 0;
    border-top: 2px solid transparent;
    border-left: 6px solid #f4f4f4;
    border-bottom: 2px solid transparent;
    transform: translateX(16px) translateY(-4px) rotate(30deg);
}

.arrowGroup {
    margin-left: auto;
    display: flex;
    align-items: flex-start;
}

.arrowOne {
    width: 0;
    border-top: 8px solid #f4f4f4;
    border-right: 8px solid transparent;
    transform: translateY(16px);
}

.arrowTwo {
    background-color: #f4f4f4;
    flex-grow: 0;
    height: 12px;
    width: 16px;
    transform: translateY(-4px);
}

.arrowThree {
    width: 0;
    height: 0;
    border-top: 8px solid #f4f4f4;
    border-left: 8px solid transparent;
    transform: translateY(-4px) translateX(8px);
}

.embedColumn {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    margin-bottom: 24px;
}

.columnBarOne {
    background-color: #f4f4f4;
    border-radius: 4px;
    flex-grow: 0;
    height: 14px;
    margin-bottom: 6px;
    width: 224px;
}

.columnBarTwo {
    background-color: #f4f4f4;
    border-radius: 4px;
    flex-grow: 0;
    height: 14px;
    width: 144px;
}
