.ImageBlockModal {
    width: 100%;
}

.title {
    display: flex;
    line-height: var(--premium-heading-line-height);
    gap: var(--smallPadding);
}

.preview-image-container {
    margin-bottom: var(--tinyPadding);
    padding: 0 var(--smallPadding);

    > img {
        width: 100%;
        height: auto;
    }
}

.file-name {
    font-size: var(--fontSize-small);
    font-family: var(--poppins);
    color: rgb(var(--mainColor));
    width: 100%;
}
