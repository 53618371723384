.RadioButton {
    display: flex;
    gap: var(--smallPadding);
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    margin: 0;
    padding: 0;

    input {
        margin: 0;
        padding: 0;
    }
}
