.HeadingsSelector {
    position: relative;

    .toolbar {
        min-width: 110px;
        font-size: 0.875em;
        font-weight: normal;
    }

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    .paragraph {
        color: rgb(var(--textColor));
        font-family: var(--text-font-family);
        font-weight: 600;
        text-align: left;
        &.active {
            background: rgb(var(--sectionBack));
        }
    }

    .h1 {
        font-size: 1.5em;
    }
    .h2 {
        font-size: 1.375em;
    }
    .h3 {
        font-size: 1.25em;
    }
    .h4 {
        font-size: 1.125em;
    }
    .h5 {
        font-size: 1em;
    }
    .h6 {
        font-size: 0.875em;
    }
}
