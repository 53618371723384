.ToolbarList {
    display: flex;
    flex-direction: column;
    width: 100%;

    > div {
        cursor: pointer;
        display: block;
        width: 100%;
        padding: var(--standardPadding) var(--largePadding);
        &:hover {
            background-color: rgb(var(--lightSectionBorder));
        }
    }
}
