.EmbedInstagramModal {
    .title {
        display: flex;
        align-items: center;
    }

    .instagram-post-form {
        display: flex;
        justify-content: space-between;
        gap: var(--standardPadding);

        .instagram-post-footer {
            padding-top: var(--smallPadding);
        }

        [class*='DynamicForm'] {
            width: 100%;
        }
    }

    #insertInstagramPost {
        width: 100%;
    }
}
