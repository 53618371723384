@use 'mixins';

.VideoPlayer {
    position: relative;
    overflow: auto;
    aspect-ratio: 16 / 9;

    /*
     * JWplayer overwrites
     */

    :global(.jw-text) {
        @extend %caption-medium;
    }

    :global(.jw-title) {
        @extend %heading-small;

        padding-right: 6em;
    }

    :global(.jw-title-primary),
    :global(.jw-title-secondary) {
        @extend %heading-small;

        font-size: 1em !important;
    }

    :global(.jw-buffer) {
        background-color: rgba(var(--lightSectionBorder), 0.4) !important;
    }

    :global(.jw-display-controls) {
        display: none;
    }

    :global(.jw-controlbar) {
        :global(.jw-settings-sharing) {
            order: 1;
            margin-left: auto;
        }

        :global(.jw-icon-pip) {
            order: 2;
        }

        :global(.jw-icon-cast) {
            order: 3;
        }

        :global(.jw-icon-airplay) {
            order: 4;
        }

        :global(.jw-icon-settings) {
            order: 5;
        }

        :global(.jw-spacer) {
            order: 6;
        }

        :global(.jw-text-elapsed) {
            order: 7;
        }

        :global(.jw-text-countdown) {
            order: 8;
        }

        :global(.jw-text-duration) {
            order: 9;
            opacity: 0.6;
        }

        :global(.jw-icon-volume) {
            order: 10;
            margin-left: auto;
        }

        :global(.jw-icon-fullscreen) {
            order: 11;
        }

        :global(.jw-icon-next) {
            display: none;
        }
    }

    &.video-mode {
        :global(.jw-slider-time) {
            order: 1;
            padding: 0 var(--largePadding) var(--largePadding) !important;
            box-sizing: border-box;
            flex: 1 1 auto;
        }

        :global(.jw-state-idle) {
            :global(.jw-display-controls) {
                display: inline-block;
            }

            :global(.jw-controlbar) {
                :global(.jw-text-alt) {
                    margin-left: auto;
                }
            }
        }

        :global(.jw-icon-display),
        :global(.jw-icon-rewind),
        :global(.jw-icon-playback),
        :global(.jw-icon-forward) {
            background: rgba(0, 0, 0, 0.6);
            border: 0.65em solid rgba(0, 0, 0, 0);
            box-sizing: content-box;
            border-radius: 50%;

            svg {
                height: 100%;
                width: 100%;
            }
        }

        :global(.jw-display) {
            position: relative;
            z-index: 1;
        }

        :global(.jw-icon-display) {
            width: 1em !important;
            height: 1em !important;
            line-height: 1em !important;
        }

        :global(.jw-icon-rewind),
        :global(.jw-icon-playback),
        :global(.jw-icon-forward) {
            height: 1em;
            width: 1em;
        }

        :global(.jw-icon-display),
        :global(.jw-icon-playback) {
            font-size: 2.2em;
        }

        :global(.jw-settings-menu) {
            z-index: 1;
        }

        :global(.jw-controlbar) {
            top: 0;
            max-height: 100% !important;
            flex-flow: column;
            height: 100%;
            pointer-events: none;

            :global(.jw-slider-time),
            :global(.jw-icon) {
                pointer-events: all;
            }

            :global(.jw-button-container) {
                flex-flow: row wrap;
                flex: 100 1 auto;
                padding: var(--standardPadding) var(--standardPadding) 0 !important;
                align-content: space-between;
            }

            :global(.jw-spacer) {
                flex: 1 0 100%;
            }

            :global(.jw-text-elapsed) {
                margin-left: var(--standardPadding);
                width: auto !important;
            }

            :global(.jw-icon-rewind),
            :global(.jw-icon-playback),
            :global(.jw-icon-forward) {
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -1.2em;
                margin-left: -1.2em;
            }

            :global(.jw-icon-rewind),
            :global(.jw-icon-forward) {
                font-size: 1.5em;
                border-width: 0.5em;
            }

            :global(.jw-icon-rewind) {
                margin-left: -4.5em;
            }

            :global(.jw-icon-forward) {
                margin-left: 2.2em;
            }
        }

        :global(.jw-title) {
            display: inherit;
        }

        :global(.jw-state-playing.jw-flag-user-inactive) {
            :global(.jw-title) {
                display: none;
            }
        }

        :global(.jw-breakpoint-7) {
            :global(.countdown-notice) {
                bottom: calc(3 * var(--hugePadding));
            }
        }
    }

    &.ad-mode {
        :global(.jw-overlays) {
            z-index: 0;
        }
    }

    // FIXME: Somehow the link sharing block is semi-broken by default
    :global(div.jw-settings-content-item) {
        padding: 0;

        :global(.jw-sharing-textarea) {
            min-height: 0;
        }

        :global(.jw-sharing-link) {
            padding: 7px 0 7px 15px;
            width: 100%;
        }
    }

    // FIXME: The jwplayer config doesn't update nicely per video and it didn't allow this to be updated by js so had to use css instead
    &.sharing-disabled {
        :global(.jw-share-item) {
            display: none;
        }

        :global(.jw-settings-sharing) {
            opacity: 0;
            visibility: hidden;
        }
    }

    @include mixins.responsive(l, above) {
        &.video-mode {
            :global(.jw-flag-fullscreen) {
                :global(.jw-icon-rewind) {
                    margin-left: -6.5em;
                }

                :global(.jw-icon-forward) {
                    margin-left: 4em;
                }
            }
        }
    }

    @include mixins.responsive(m, below) {
        :global(.jw-title-primary),
        :global(.jw-title-secondary) {
            padding: 0 var(--standardPadding);
        }

        :global(.jw-display-icon-rewind),
        :global(.jw-display-icon-next),
        :global(.jw-rightclick) {
            display: none;
        }

        :global(.jw-icon-inline) {
            height: 1em;
        }

        :global(.jw-controlbar) {
            :global(.jw-text) {
                font-size: 0.75em;
            }
        }

        &.video-mode {
            :global(.jw-slider-time) {
                padding: var(--tinyPadding) var(--standardPadding) var(--standardPadding) !important;
            }

            :global(.jw-controlbar) {
                :global(.jw-text) {
                    font-size: 0.75em;
                }

                :global(.jw-button-container) {
                    padding: var(--standardPadding) 0 !important;
                    z-index: 1;

                    :global(.jw-icon-rewind),
                    :global(.jw-icon-playback),
                    :global(.jw-icon-forward) {
                        margin-top: -1.1em;
                    }

                    :global(.jw-icon-playback) {
                        display: flex !important;
                    }

                    :global(.jw-icon-display),
                    :global(.jw-icon-playback) {
                        font-size: 1.65em;
                    }
                }
            }

            // FIXME: JWplayer hides the rewind button on mobile
            :global(.jw-state-paused),
            :global(.jw-state-playing) {
                :global(.jw-button-container) {
                    :global(.jw-icon-rewind) {
                        display: flex !important;
                    }
                }
            }
        }
    }
}
