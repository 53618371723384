.ToolbarButton {
    border: none;
    min-height: 3rem;
    padding: 0 var(--standardPadding);
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    gap: var(--tinyPadding);

    &.filled,
    &:hover {
        color: rgb(var(--textColor));
        background-color: rgb(var(--lightSectionBorder));
    }

    :global(.Icon) {
        width: 24px;
        height: 24px;
    }
}
