.MatchDetailSectionArticleBlock {
    .teamVsTeam {
        display: flex;
        flex-direction: column-reverse;
        > span {
            width: 100%;
            display: block;
        }
    }
}
