.ListSelector {
    position: relative;

    .button {
        justify-content: flex-start;
    }

    .text {
        padding-left: var(--tinyPadding);
    }
}
