.ToolbarPlugin {
    position: sticky;
    top: -1px;
    z-index: 1;
    display: flex;
    background-color: rgb(var(--background));
    border-top: 1px solid rgb(var(--lightSectionBorder));
    border-bottom: 1px solid rgb(var(--lightSectionBorder));
    padding: var(--tinyPadding) var(--standardPadding);
}
