.RichTextWrapperPlugin {
    .input {
        min-height: 29.75rem;
        &:focus {
            outline: none;
        }

        :global(.underline) {
            text-decoration: underline !important;
        }

        :global(.bold) {
            font-weight: var(--font-weight-semi-bold);
        }

        :global(.italic) {
            font-style: italic;
        }

        p,
        ul,
        ol {
            margin: var(--standardPadding) var(--largePadding);
        }

        p {
            margin: var(--largePadding) 0;
        }

        ul {
            list-style: disc;
        }

        ol {
            list-style: decimal;
        }
    }
}
