@use 'mixins';

.CountdownNotice {
    @extend %tag-medium;

    position: absolute;
    overflow: hidden;
    padding: var(--smallPadding) var(--standardPadding);
    bottom: calc(2 * var(--hugePadding));
    right: var(--largePadding);
    color: rgb(var(--invertedBackground));
    background: rgb(var(--lightSectionBack));

    :global(.theme-gp) &,
    :global(.theme-vp) &,
    :global(.theme-br) & {
        border-radius: var(--standardRadius);
    }

    @include mixins.responsive(m, below) {
        padding: var(--tinyPadding) var(--smallPadding);
        bottom: calc(2 * var(--hugePadding));
        right: var(--standardPadding);
        font-size: 0.625em;
    }

    @include mixins.responsive(s, below) {
        display: table;
        margin: 0 auto;
        bottom: calc(5 * var(--tinyPadding));
        left: 0;
        right: 0;
    }
}
