.ActionButtons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.buttonGroup {
    padding: var(--smallPadding);
    background: rgb(var(--lightSectionBack));
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    height: fit-content;
    width: fit-content;
}

.button {
    border: none;
    background: rgb(var(--lightSectionBack));
    color: rgb(var(--titleColor));

    &:hover {
        background: rgb(var(--sectionBorder));
        color: rgb(var(--titleColor));
    }
}
