.Editor {
    .container {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .inner {
        position: relative;
        padding: 0 var(--hugePadding);
        background-color: rgb(var(--lightSectionBack));
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .label {
            color: rgb(var(--textColor));
            font-size: 1em;
            font-style: normal;
            font-weight: 600;
            line-height: 1.25rem;
        }

        .buttons-container {
            display: flex;
            align-items: center;
        }
    }
}
