.ButtonWithModal {
    width: 100%;
    border: none;
    border-radius: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;

    &:hover {
        background: rgb(var(--mainColor));
        color: rgb(var(--invertedTextColor));
        border-radius: var(--standardRadius);
    }
}
