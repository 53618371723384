.LinkEditor {
    display: flex;
    margin-top: calc(var(--tinyPadding) * -1);
    .field {
        padding: var(--smallPadding);
    }
    input {
        min-width: 180px;
        max-width: 400px;
        margin: 0;
    }
    .error {
        border-color: rgb(var(--negativeColor));
    }
}

.hidden {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
