.InlineButton {
    z-index: 100000;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    color: rgb(255, 255, 255);
    border: solid 2px rgb(var(--mainColor));
    background: rgb(var(--mainColor));
    transition: 0.3s;
    cursor: pointer;
    padding: var(--standardPadding);

    &:hover {
        background: rgb(255, 255, 255);
        color: rgb(var(--mainColor));
    }
}
